
import * as Sentry from '@sentry/browser'
import { defineComponent, ref, reactive, toRefs, onBeforeMount } from 'vue'
import { get as getOrderConfirm, Confirm } from '@/api/customer/order/confirm'
import { comma } from '@/api/utils'
import { useRoute, useRouter } from 'vue-router'
import { useTranslation } from '@/locales'
import { serviceFactory } from '@/service'

export default defineComponent({
  setup() {
    const items = reactive<{ invoice: Confirm | undefined }>({
      invoice: undefined,
    })
    const { invoice } = toRefs(items)
    const router = useRouter()
    const route = useRoute()
    const noService = ref('')
    const customerAddress = ref('')
    const productName = ref('')

    const { locale } = route.params
    const { query } = route

    const { t, d, n } = useTranslation()

    onBeforeMount(() => {
      const sessionId = query.id as string

      getOrderConfirm(sessionId)
        .then((res) => {
          console.log(res)
          const {
            postalCode,
            country,
            prefecture,
            city,
            address1,
            address2,
            services,
          } = res

          const {
            startDate,
            endDate,
            paymentFrequency,
            serviceCategory,
            amount,
            domain_name: domainName,
          } = services[0]

          customerAddress.value = t(
            '{address1} {address2} {city} {prefecture} {postalCode} {country}',
            { address1, address2, city, prefecture, postalCode, country }
          )
          const serviceManager = serviceFactory(serviceCategory, t, d)

          productName.value = serviceManager.getServiceName(
            amount,
            startDate,
            endDate,
            paymentFrequency,
            domainName
          )

          invoice.value = res
        })
        .catch(() => {
          noService.value = t('There is not available services now')
          invoice.value = undefined
        })
    })

    const exec = () => {
      router.push({
        path: `/${locale}/service/order/card`,
        query,
      })
    }

    return {
      comma,
      invoice,
      noService,
      customerAddress,
      productName,
      t,
      n,
      d,
      exec,
    }
  },
})
