import { errorHandler } from '@/api/utils'
import { http } from '@/plugins/ky'

export type Confirm = {
  customerName: string
  postalCode: string
  country: string
  prefecture: string
  city: string
  address1: string
  address2: string
  currentDate: string
  currency: string
  salesTax: number
  total: number
  totalWithTax: number
  services: {
    name: string
    startDate: string
    endDate: string
    serviceCategory: string
    paymentFrequency: string
    total: number
    ratePrice: number
    amount: number
    domain_name?: string
  }[]
}

export const get = (sessionId: string) =>
  new Promise<Confirm>((resolve, reject) =>
    http
      .get('customer/order/confirm', {
        searchParams: {
          sessionId,
        },
      })
      .then(async (r: any) => {
        const res = await r.json()
        resolve(res)
      })
      .catch(async ({ response, message }: any) => {
        await errorHandler(response, message)
        reject(undefined)
      })
  )
