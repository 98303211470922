<template>
  <div class="flex justify-center items-center">
    <div class="mt-8">
      <div v-if="invoice != null">
        <div class="flex justify-between md:mr-20">
          <p class="text-left text-xs text-gray-500 pb-2">
            {{ t('Billing to') }}
          </p>
          <p class="text-left text-xs text-gray-500 pb-2">
            {{ d(new Date(invoice.currentDate), 'ymd') }}
          </p>
        </div>

        <h1 class="text-left mb-1 text-2xl font-bold">
          {{ invoice.customerName }}
        </h1>

        <p class="text-xs md:text-sm text-gray-500 mb-8 text-left">
          {{ customerAddress }}
        </p>

        <table class="border-b-2">
          <tr class="text-left border-b-2">
            <th class="py-2 text-xs md:text-base">{{ t('Product') }}</th>
            <th class="py-2 text-xs md:text-base">{{ t('Rate price') }}</th>
            <th class="py-2 text-xs md:text-base">{{ t('Amount') }}</th>
            <th class="py-2 text-xs md:text-base">{{ t('Total amount') }}</th>
          </tr>

          <tr v-for="(item, index) in invoice.services" :key="index">
            <td class="w-96 py-2 text-left text-xs md:text-base">
              {{ productName }}
            </td>
            <td class="w-60 py-2 text-left text-xs md:text-base">
              {{ n(item.ratePrice, 'currency') }}
            </td>
            <td class="w-60 py-2 text-left text-xs md:text-base">
              {{ item.amount }}
            </td>
            <td class="w-60 py-2 text-left text-xs md:text-base">
              {{ n(item.total, 'currency') }}
            </td>
          </tr>
        </table>

        <p class="mt-1 mb-20 text-left text-xs text-gray-500">
          {{ t('Date is caliculated as UTC') }}
        </p>
        <div class="flex justify-center md:justify-end items-center">
          <div class="md:mr-20">
            <div class="flex mb-6">
              <div class="">
                <h2 class="w-32 text-left mb-2">{{ t('Subtotal') }}：</h2>
                <h2 class="w-32 text-left mb-2">{{ t('Sales tax') }}：</h2>
                <h2 class="w-32 text-left mb-2 font-bold">
                  {{ t('Total amount with tax') }}:
                </h2>
              </div>
              <div class="">
                <p class="text-right mb-2">
                  {{ n(invoice.total, 'currency') }}
                </p>
                <p class="text-right mb-2">
                  {{ n(invoice.salesTax, 'currency') }}
                </p>
                <p class="text-right font-bold mb-2">
                  {{ n(invoice.totalWithTax, 'currency') }}
                </p>
              </div>
            </div>

            <button class="bg-blue-800 text-white px-10 py-1" @click="exec">
              {{ t('Buy now') }}
            </button>
          </div>
        </div>

        <div class="text-left mt-32">
          <h2 class="text-gray-700">{{ t('Qualitia.co.,Ltd') }}</h2>
          <p class="text-xs md:text-sm text-gray-500 mb-1">
            {{
              t(
                '3-11-10, Nihombashikayabacho, Chuo Ku, Tokyo To, 103-0025, Japan'
              )
            }}
          </p>
          <div class="text-xs md:text-sm flex text-gray-500">
            <p class="w-10">{{ t('Phone') }}:</p>
            <p>{{ t('+81-3-5623-2530') }}</p>
          </div>
        </div>
      </div>
      <div v-else>
        <p class="text-gray-500 text-xl">
          {{ noService }}
        </p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import * as Sentry from '@sentry/browser'
import { defineComponent, ref, reactive, toRefs, onBeforeMount } from 'vue'
import { get as getOrderConfirm, Confirm } from '@/api/customer/order/confirm'
import { comma } from '@/api/utils'
import { useRoute, useRouter } from 'vue-router'
import { useTranslation } from '@/locales'
import { serviceFactory } from '@/service'

export default defineComponent({
  setup() {
    const items = reactive<{ invoice: Confirm | undefined }>({
      invoice: undefined,
    })
    const { invoice } = toRefs(items)
    const router = useRouter()
    const route = useRoute()
    const noService = ref('')
    const customerAddress = ref('')
    const productName = ref('')

    const { locale } = route.params
    const { query } = route

    const { t, d, n } = useTranslation()

    onBeforeMount(() => {
      const sessionId = query.id as string

      getOrderConfirm(sessionId)
        .then((res) => {
          console.log(res)
          const {
            postalCode,
            country,
            prefecture,
            city,
            address1,
            address2,
            services,
          } = res

          const {
            startDate,
            endDate,
            paymentFrequency,
            serviceCategory,
            amount,
            domain_name: domainName,
          } = services[0]

          customerAddress.value = t(
            '{address1} {address2} {city} {prefecture} {postalCode} {country}',
            { address1, address2, city, prefecture, postalCode, country }
          )
          const serviceManager = serviceFactory(serviceCategory, t, d)

          productName.value = serviceManager.getServiceName(
            amount,
            startDate,
            endDate,
            paymentFrequency,
            domainName
          )

          invoice.value = res
        })
        .catch(() => {
          noService.value = t('There is not available services now')
          invoice.value = undefined
        })
    })

    const exec = () => {
      router.push({
        path: `/${locale}/service/order/card`,
        query,
      })
    }

    return {
      comma,
      invoice,
      noService,
      customerAddress,
      productName,
      t,
      n,
      d,
      exec,
    }
  },
})
</script>
